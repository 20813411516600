import {Component} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'my-fruit-landing';

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(event);
        gtag('config', 'UA-157707019-1', { page_path: event.urlAfterRedirects });
      }
    });
  }
}

